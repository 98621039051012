
import 'react-multi-carousel/lib/styles.css';
import pythonIcon from "../assets/img/pythonIcon.svg"
import javascriptIcon from "../assets/img/javascriptIcon.svg"
import kotlinIcon from "../assets/img/kotlinIcon.svg"
import cppIcon from "../assets/img/cppIcon.svg"
import rIcon from "../assets/img/rIcon.svg"
import mongodbIcon from "../assets/img/mongodbIcon.svg"
export const Skills = () => {

  return (
    <section className="skill" id="skills">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="skill-bx">
                    <h2>Skills</h2>
                    <div className="skill-row">
                        <div className="skill-item">
                            <img src={pythonIcon} alt="Python Logo" />
                            <h5>Python</h5>
                        </div>
                        <div className="skill-item">
                            <img src={javascriptIcon} alt="JavaScript Logo" />
                            <h5>JavaScript</h5>
                        </div>
                        <div className="skill-item">
                            <img src={kotlinIcon} alt="Kotlin Logo" />
                            <h5>Kotlin</h5>
                        </div>
                        <div className="skill-item">
                            <img src={cppIcon} alt="C++ Logo" />
                            <h5>C++</h5>
                        </div>
                        <div className="skill-item">
                            <img src={rIcon} alt="R" />
                            <h5>R</h5>
                        </div>
                        <div className="skill-item">
                            <img src={mongodbIcon} alt="MongoDB Logo" />
                            <h5>MongoDB</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  )
}
