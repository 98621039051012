import { Container, Row, Col } from "react-bootstrap";
import informalHeadshot2 from '../assets/img/informalHeadshot2.jpeg';

export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7} className="banner-text-col">
              <h1>{`Hi, I'm Hanson`} <span className="wrap"></span></h1>
              <p>Welcome to my website 👋. I’m an undergraduate student at the University of California – Davis, with a passion for developing data analytics tools, building web applications, and crafting creative solutions. Feel free to reach out—I’d love to chat, share ideas, or collaborate on something exciting.</p> 
          </Col>
          <Col xs={12} md={6} xl={5} className="banner-img-col">
              <img src={informalHeadshot2} alt="headshot"/>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
